import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import Dynamic from '../components/dynamic'

import PortfolioSections from '../components/portfolio/sections'
import PortfolioText from '../components/portfolio/text'
import PortfolioCredits from '../components/portfolio/credits'

const ProjectPage = ({ data }) => {
	const project = data.strapi.project

	let background = '#FFFFFF'
	let color = '#333333'

	let link, text

	project.settings.forEach(setting => {
		switch (setting.__typename) {
			// Page Background Setting
			case 'STRAPI_ComponentSettingsPageColor':
				background = setting.color
				color = setting.theme === 'Light' ? '#FFFFFF' : '#333333'
				break

			// Website CTA Setting
			case 'STRAPI_ComponentSettingsWebsiteCta':
				link = setting.link
				text = setting.text
				break

			default:
				break
		}
	})

	return (
		<Layout page={data.strapi.portfolio} metadata={project.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Title Section */}
				<section className="py-40 text-center">
					<h1 className="pb-5 sm:pb-10">{project.title}</h1>
					<h3 className="text-grey">{project.subtitle}</h3>
				</section>

				{/* Hero Unit */}
				<section className="pt-30 -mb-project">
					<Dynamic component={project.hero} />
				</section>
			</div>

			{/* Project Details */}
			<div className="w-full" style={{ backgroundColor: background }}>
				{/* Content Section */}
				<div className="container">
					<div className="pt-project">
						<section className="py-40 lg:py-80 xl:py-120">
							<PortfolioText content={project.content} />
						</section>
					</div>
				</div>

				{/* Dynamic Sections */}
				<PortfolioSections component={project.sections} />

				{/* Credits Sections */}
				{/* <PortfolioCredits
					credits={project.credits}
					theme={color}
					link={link}
					text={text}
				/> */}
			</div>
		</Layout>
	)
}

ProjectPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			project: PropTypes.shape({
				title: PropTypes.string.isRequired,
				subtitle: PropTypes.string.isRequired,
				hero: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				content: PropTypes.string.isRequired,
				sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				credits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				settings: PropTypes.arrayOf(
					PropTypes.shape({
						id: PropTypes.string,
						color: PropTypes.string,
						theme: PropTypes.string,
						link: PropTypes.string,
						text: PropTypes.string,
					})
				).isRequired,
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
			portfolio: PropTypes.shape({}).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	query Projects($id: ID!) {
		strapi {
			project(id: $id, publicationState: PREVIEW) {
				title
				subtitle
				hero {
					__typename
					... on STRAPI_ComponentSectionSingleImage {
						id
						image {
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 1290
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionEmbed {
						id
						video_id
						title
						padding
					}
				}
				content
				sections {
					__typename
					... on STRAPI_ComponentSectionImages {
						id
						images {
							id
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 1500
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionFlexibleImages {
						id
						flexible {
							id
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: FULL_WIDTH
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionEmbed {
						id
						video_id
						title
						padding
					}
					... on STRAPI_ComponentSectionImageImage {
						id
						left_image {
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 735
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
									)
								}
							}
						}
						right_image {
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 735
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionImageEmbed {
						id
						left_image {
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 735
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
									)
								}
							}
						}
						right_embed {
							video_id
							title
							padding
						}
					}
					... on STRAPI_ComponentSectionEmbedImage {
						id
						left_embed {
							video_id
							title
							padding
						}
						right_image {
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 735
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionEmbedEmbed {
						id
						left_embed {
							video_id
							title
							padding
						}
						right_embed {
							video_id
							title
							padding
						}
					}
					... on STRAPI_ComponentSectionFixedCarousel {
						id
						number
						speed
						slides {
							id
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: FULL_WIDTH
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionFlexibleCarousel {
						id
						visible: number
						speed
						slides {
							id
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: FULL_WIDTH
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionImageContent {
						id
						image_position: position
						image_content: content
						image {
							url
							ext
							name
							height
							width
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 735
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
									)
								}
							}
						}
					}
					... on STRAPI_ComponentSectionEmbedContent {
						id
						embed_position: position
						embed_content: content
						embed {
							video_id
							title
							padding
						}
					}
					... on STRAPI_ComponentSectionSpacer {
						id
						space
					}
					... on STRAPI_ComponentSectionText {
						id
						content
					}
				}
				credits {
					id
					heading
					team {
						id
						name
					}
				}
				settings {
					__typename
					... on STRAPI_ComponentSettingsPageColor {
						id
						color
						theme
					}
					... on STRAPI_ComponentSettingsWebsiteCta {
						id
						link
						text
					}
				}
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
			portfolio: portfolioPage {
				footer_headline
				footer_link_url
				footer_link_text
			}
		}
	}
`

export default ProjectPage
